.new-review-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem ;
  gap: 1rem;
  width: 20rem;
  height: 20rem;
  background-color: white;
  border: 4px solid black;
  border-radius: 5%;
}

.new-review-input{
  font-size: 12px;
  background: rgba(0, 98, 255, 0.103);
  resize: none;
  width: 18.7rem;
  padding: 8px 8px;
  border: 3px solid black;
}
.submit-reivew-button{
  font-size: 11px;
  padding: 8px 6.66rem;
  border: 3px solid grey;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
}
.submit-reivew-button{
  font-size: 11px;
  padding: 8px 6.66rem;
  border: 3px solid grey;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
}

.submit-reivew-button:active {
  background-color: #FF5A5F ;
  border: 3px solid black;
  color: white;
  box-shadow: 1px 2px black;
  transform: translateY(2px);
}
.rating-input{
  display: flex;
  flex-direction: row;
}
.star-rating-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
