.signupForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 2rem ;
  gap: 12px;
  width: 20rem;
  height: auto;
  background-color: white;
  border-radius: 25px ;

}

.signupForm > *{
  display: flex;
  flex-direction: column;
}

.signupForm > button{
  display: flex;
  flex-direction: center;
  align-items: center;
  width: 100%;
  height: 2rem;
}

.errors{
  color: #FF5A5F;
}

.signupForm >label > input{
  border: 2px solid black;
  padding: 4px 4px;
}
