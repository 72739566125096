h2{
  margin:0;
  padding:0;
}


.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:1rem 2rem ;
  gap: .5rem;
  width: 20rem;
  height: auto;
  background-color: white;
  border-radius: 15%;

}

.loginForm > *{
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.loginForm > label > input{
  padding: 4px;
}



.login-button,
.sign-up-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2rem;
  background: #FF5A5F;
  /* padding: 1px 1px; */
  color: white;
  width: 100%;
  border: 1px solid black;
  box-shadow: 3px 3px 3px black ;
}

.login-button:disabled,
.sign-up-button:disabled{
  background: rgb(193, 193, 193);
}

.login-button:active,
.sign-up-button:active{
  color: #FF5A5F;
  background: white;
  box-shadow: 1px 1px 1px black ;
}

.logInText{
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.581);
  border-top-right-radius: 10%;
}

.login-modal{
  background: white;
  border-radius: 5%;

}

.login-error{
  padding: none ;
  display: flex;
  justify-content: center;
  color: #FF5A5F;
}
