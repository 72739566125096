.create-new-spot-container,
.create-update-spot-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.new-spot-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 2rem;
  width: 30rem;
  /* border: 1px solid red; */
}

.location-info-container,
.set-name-container,
.set-description-container,
.set-price-container,
.set-spot-images-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  padding: 1rem 8px;
  border-bottom: 1px solid black;
}

.new-spot-input-container>input,
.price-dollar-sign-span>input,
.description-input-container,
.set-price-container>input {
  border: 2px solid black;
  padding: 4px 4px;
  width: 95%;
}

.new-spot-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.city-state-container {
  display: grid;
  grid-template-columns: 1.3fr .8fr;
  gap: 1.5rem;
}
.city-state-container >input {
  display: grid;
  grid-template-columns: 1.3fr .8fr;
  gap: 1.5rem;
}

.span-validation-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.price-dollar-sign-span {
  display: flex;
  width: 97%;
  gap: .5rem;
  /* border: 1px solid red; */
}

.create-update-spot-button {
  padding: 5px;
  width: 100%;
}
